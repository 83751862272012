import { useState } from 'react'
import './Carrousel.scss'

function Carrousel({ images, altImg }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const totalImages = images.length

  const goToPrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1))
  }

  const goToNext = () => {
    setCurrentIndex(prevIndex => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1))
  }

  if (totalImages === 0) {
    return null // ou un placeholder si aucune image n'est disponible
  }

  return (
    <section className="carrousel">
      <img className="carrousel__picture" src={images[currentIndex]} alt={altImg} />
      {totalImages > 1 && (
        <>
          <p className="carrousel__counter">{`${currentIndex + 1} / ${totalImages}`}</p>
          <button className="carrousel__btn carrousel__btn--left" onClick={goToPrevious}>
            <svg
              className="carrousel__btn--svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
          </button>
          <button className="carrousel__btn carrousel__btn--right" onClick={goToNext}>
            <svg
              className="carrousel__btn--svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
          </button>
        </>
      )}
    </section>
  )
}

export default Carrousel
